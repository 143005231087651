import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import Cookies from "universal-cookie";

const Login = () => {
    const [urlCode, setUrlCode] = useState<string | undefined>(undefined);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const state = urlParams.get("state");


        let code = urlParams.get("code");

        if (code) {
            setUrlCode(code);
        }

        async function fetchUserToken() {
            try {
                const tokenDataResponse = await axios.post(
                    "https://discord.com/api/oauth2/token",
                    {
                        client_id: process.env.REACT_APP_DISCORD_CLIENT_ID,
                        client_secret: process.env.REACT_APP_DISCORD_CLIENT_SECRET,
                        grant_type: "authorization_code",
                        code: urlCode,
                        redirect_uri: process.env.REACT_APP_URL + "login",
                        scope: "identify",
                    },
                    {
                        headers: { "Content-Type": "application/x-www-form-urlencoded" },
                    }
                );

                const jwtToken = await axios.post(
                    process.env.REACT_APP_API_URL + "login",
                    { discordToken: tokenDataResponse.data.access_token }
                );

                const cookies = new Cookies();
                cookies.set("discordGlobalName", jwtToken.data.discordGlobalName, {
                    domain: process.env.REACT_APP_DOMAIN,
                    path: "/",
                    maxAge: 3 * 60 * 60,
                });
                cookies.set("JWT_TOKEN", jwtToken.data.jwt, {
                    domain: process.env.REACT_APP_DOMAIN,
                    path: "/",
                    maxAge: 3 * 60 * 60,
                });


                if (state) {
                    const redirectUrl = decodeURIComponent(state);
                    window.location.href = redirectUrl;
                } else {
                    window.history.go(-2); // Redirige après la connexion
                }
            } catch (error) {
                console.error("Erreur : ", error);
            }
        }

        if (urlCode) {
            fetchUserToken();
        }
    }, [urlCode]);

    return <></>;
};

export default Login;
import { stylesWithCssVar } from "../../../utils/motion";
import { useScroll, useTransform, motion } from "framer-motion";
import { useRef } from "react";

const animationOrder = {
    showImageTwitchStart: 0,
    showImageTwitchEnd: 0.1,
    showTitleTwitchStart: 0.15,
    showTitleTwitchEnd: 0.2,
    hideTitleTwitchStart: 0.25,
    hideTitleTwitchEnd: 0.3,
    showTextTwitchStart: 0.35,
    showTextTwitchEnd: 0.4,
    hideTextTwitchStart: 0.45,
    hideTextTwitchEnd: 0.5,
    showImageYoutubeStart: 0.55,
    showImageYoutubeEnd: 0.6,
    showTitleYoutubeStart: 0.6,
    showTitleYoutubeEnd: 0.65,
    hideTitleYoutubeStart: 0.7,
    hideTitleYoutubeEnd: 0.75,
    reduceImageTwitchStart: 0.65,
    reduceImageTwitchEnd: 0.7,
    showBackImageTwitchStart: 0.7,
    showBackImageTwitchEnd: 0.75,
    moveImageTwitchStart: 0.8,
    moveImageTwitchEnd: 0.95,
    reduceImageYoutubeStart: 0.8,
    reduceImageYoutubeEnd: 0.95,
    showCaptionsStart: 0.9,
    showCaptionsEnd: 0.95,
    hideCaptionsStart: 1.1,
    hideCaptionsEnd: 1.1,
    hideImageTwitchStart: 1.1,
    hideImageTwitchEnd: 1.1,
    hideImageYoutubeStart: 1.1,
    hideImageYoutubeEnd: 1.1,
};

const Networks = () => {
    const targetRef = useRef<HTMLDivElement | null>(null);
    const { scrollYProgress } = useScroll({
        target: targetRef,
        offset: ["start end", "end end"],
    });

    const imageTwitchOpacity = useTransform(
        scrollYProgress,
        [
            animationOrder.showImageTwitchStart,
            animationOrder.showImageTwitchEnd,
            animationOrder.showImageYoutubeStart,
            animationOrder.showImageYoutubeEnd,
            animationOrder.showBackImageTwitchStart,
            animationOrder.showBackImageTwitchEnd,
            animationOrder.hideImageYoutubeStart,
            animationOrder.hideImageYoutubeEnd,
        ],
        [0, 1, 1, 0, 0, 1, 1, 0]
    );
    const imageTwitchScale = useTransform(
        scrollYProgress,
        [
            animationOrder.showImageTwitchStart,
            animationOrder.showImageTwitchEnd,
            animationOrder.reduceImageTwitchStart,
            animationOrder.reduceImageTwitchEnd,
        ],
        [3, 1, 1, 0.5]
    );
    const imageTwitchX = useTransform(
        scrollYProgress,
        [
            animationOrder.showImageTwitchStart,
            animationOrder.hideTitleTwitchStart,
            animationOrder.hideTitleTwitchEnd,
            animationOrder.showTextTwitchEnd,
            animationOrder.hideTextTwitchStart,
            animationOrder.hideTextTwitchEnd,
            animationOrder.showImageYoutubeStart,
            animationOrder.showImageYoutubeEnd,
            animationOrder.moveImageTwitchStart,
            animationOrder.moveImageTwitchEnd,
        ],
        ["15vw", "15vw", "17vw", "-15vw", "-15vw", "-17vw", "0vw", "15vw", "15vw", "-15vw"]
    );

    const imageYoutubeOpacity = useTransform(
        scrollYProgress,
        [
            animationOrder.hideTextTwitchEnd,
            animationOrder.showImageYoutubeStart,
            animationOrder.hideImageYoutubeStart,
            animationOrder.hideImageYoutubeEnd,
        ],
        [0, 1, 1, 0]
    );
    const imageYoutubeX = useTransform(
        scrollYProgress,
        [
            animationOrder.hideTextTwitchEnd,
            animationOrder.showImageYoutubeStart,
            animationOrder.showImageYoutubeEnd,
        ],
        ["-17vw", "0vw", "15vw"]
    );
    const imageYoutubeScale = useTransform(
        scrollYProgress,
        [
            animationOrder.showImageYoutubeStart,
            animationOrder.reduceImageYoutubeStart,
            animationOrder.reduceImageYoutubeEnd,
        ],
        [1, 1, 0.5]
    );

    const titleTwitchOpacity = useTransform(
        scrollYProgress,
        [
            animationOrder.showTitleTwitchStart,
            animationOrder.showTitleTwitchEnd,
            animationOrder.hideTitleTwitchStart,
            animationOrder.hideTitleTwitchEnd,
        ],
        [0, 1, 1, 0]
    );
    const titleTwitchTranslateY = useTransform(
        scrollYProgress,
        [
            animationOrder.showTitleTwitchStart ,
            animationOrder.showTitleTwitchEnd,
            animationOrder.hideTitleTwitchStart,
            animationOrder.hideTitleTwitchEnd,
        ],
        ["4rem", "0rem", "0rem", "-4rem"]
    );

    const textTwitchOpacity = useTransform(
        scrollYProgress,
        [
            animationOrder.showTextTwitchStart ,
            animationOrder.showTextTwitchEnd,
            animationOrder.hideTextTwitchStart,
            animationOrder.hideTextTwitchEnd,
        ],
        [0, 1, 1, 0]
    );
    const textTwitchTranslateY = useTransform(
        scrollYProgress,
        [
            animationOrder.showTextTwitchStart ,
            animationOrder.showTextTwitchEnd,
            animationOrder.hideTextTwitchStart,
            animationOrder.hideTextTwitchEnd,
        ],
        ["4rem", "0rem", "0rem", "-4rem"]
    );

    const titleYoutubeOpacity = useTransform(
        scrollYProgress,
        [
            animationOrder.showTitleYoutubeStart,
            animationOrder.showTitleYoutubeEnd,
            animationOrder.hideTitleYoutubeStart,
            animationOrder.hideTitleYoutubeEnd,
        ],
        [0, 1, 1, 0]
    );

    const titleYoutubeY = useTransform(
        scrollYProgress,
        [
            animationOrder.showTitleYoutubeStart,
            animationOrder.showTitleYoutubeEnd,
            animationOrder.hideTitleYoutubeStart,
            animationOrder.hideTitleYoutubeEnd,
        ],
        ["4rem", "0rem", "0rem", "-4rem"]
    );

    const captionsOpacity = useTransform(
        scrollYProgress,
        [
            animationOrder.showCaptionsStart,
            animationOrder.showCaptionsEnd,
            animationOrder.hideCaptionsStart,
            animationOrder.hideCaptionsEnd,
        ],
        [0, 1, 1, 0]
    );

    const pagination1Opacity = useTransform(
        scrollYProgress,
        [
            animationOrder.showImageTwitchStart,
            animationOrder.showImageTwitchEnd,
            animationOrder.hideTitleTwitchStart,
            animationOrder.hideTitleTwitchEnd,
        ],
        [0, 1, 1, 0]
    )
    const pagination2Opacity = useTransform(
        scrollYProgress,
        [
            animationOrder.hideTitleTwitchStart,
            animationOrder.hideTitleTwitchEnd,
            animationOrder.hideTextTwitchStart,
            animationOrder.hideTextTwitchEnd,
        ],
        [0, 1, 1, 0]
    )
    const pagination3Opacity = useTransform(
        scrollYProgress,
        [
            animationOrder.hideTextTwitchStart,
            animationOrder.hideTextTwitchEnd,
            animationOrder.hideTitleYoutubeStart,
            animationOrder.hideTitleYoutubeEnd,
        ],
        [0, 1, 1, 0]
    )
    const pagination4Opacity = useTransform(
        scrollYProgress,
        [
            animationOrder.hideTitleYoutubeStart,
            animationOrder.hideTitleYoutubeEnd,
            animationOrder.hideCaptionsStart,
            animationOrder.hideCaptionsEnd,
        ],
        [0, 1, 1, 0]
    )

    const position = useTransform(scrollYProgress, (pos) =>
        pos >= 1 ? "relative" : "fixed"
    );

    const navigate = (name: any) => {
        const urls = [
            {
                name: 'twitch',
                path: 'https://www.twitch.tv/aspig'
            },
            {
                name: 'youtube',
                path: 'https://www.youtube.com/@AsPigTv'
            },
        ]
        const url = urls.find(item => item.name === name);
        if (url) {
            window.open(url.path, '_blank')
        }
    }

    return (
        <section className="networks" ref={targetRef}>
            <div className="relative h-[500vh]">
                <div className="sticky top-1/2 flex origin-center -translate-y-1/2 justify-center">
                    <motion.div
                        className="translate-x-centered-offset absolute left-1/2 top-1/2 flex w-[50vw] -translate-y-1/2 scale-[var(--scale)] flex-col items-center justify-center"
                        style={stylesWithCssVar({
                            opacity: imageTwitchOpacity,
                            "--x": imageTwitchX,
                            "--scale": imageTwitchScale,
                        })}
                    >
                        <img src="https://i.imgur.com/tFYfJo4.jpeg" className="h-auto w-full rounded-xl  z-10" />
                        <motion.div
                            style={stylesWithCssVar({
                                opacity: captionsOpacity,
                            })}
                            whileHover={{
                                scale: 1.05,
                                cursor: "pointer"
                            }}
                            whileTap={{ scale: 0.9 }}
                            transition={{ type: "spring", stiffness: 400, damping: 17 }}
                            className="translate-y-centered-offset mt-2 text-md leading-tight text-white"
                        >
                            <img className="mr-3 inline-block h-12 w-12" src="https://i.imgur.com/RANJ45d.png" alt="Logo Twitch"/>
                            <span onClick={() => navigate('twitch')}>Twitch.tv/Aspig</span>
                        </motion.div>
                    </motion.div>
                    <motion.div
                        className="translate-x-centered-offset absolute left-1/2 top-1/2 flex w-[50vw] -translate-y-1/2 scale-[var(--scale)] flex-col items-center justify-center"
                        style={stylesWithCssVar({
                            opacity: imageYoutubeOpacity,
                            "--x": imageYoutubeX,
                            "--scale": imageYoutubeScale,
                        })}
                    >
                        <img src="https://i.imgur.com/MpzZurM.jpeg" className="h-auto w-full rounded-xl" />
                        <motion.div
                            style={stylesWithCssVar({
                                opacity: captionsOpacity,
                            })}
                            whileHover={{
                                scale: 1.05,
                                cursor: "pointer"
                            }}
                            whileTap={{ scale: 0.9 }}
                            transition={{ type: "spring", stiffness: 400, damping: 17 }}
                            className="translate-y-centered-offset mt-2 text-md leading-tight text-white"
                        >
                            <img className="mr-3 inline-block h-12 w-12" src="https://i.imgur.com/KvSPAoG.png" alt="Logo Youtube"/>
                            <span onClick={() => navigate('youtube')}>Youtube.com/@AsPigTv</span>
                        </motion.div>
                    </motion.div>
                </div>

                <motion.div
                    className="translate-y-centered-offset top-1/2 w-[40vw] flex flex-col justify-center items-center text-center text-lg leading-tight"
                    style={stylesWithCssVar({
                        opacity: titleYoutubeOpacity,
                        "--y": titleYoutubeY,
                        position,
                    })}
                >
                    <p className="text-primary">Youtuber</p>
                    <p className="text-background">Rejoignez-nous sur Youtube, <br/> Guides, Let's Play</p>
                </motion.div>

                <motion.div
                    style={stylesWithCssVar({
                        opacity: titleTwitchOpacity,
                        "--y": titleTwitchTranslateY,
                        position,
                    })}
                    className="translate-y-centered-offset top-1/2 w-[40vw] flex flex-col justify-center items-center text-center text-lg leading-tight"
                >
                    <p>Streamer Twitch</p>
                    <p className="text-primary">Multigaming</p>
                </motion.div>

                <motion.div
                    style={stylesWithCssVar({
                        opacity: textTwitchOpacity,
                        "--y": textTwitchTranslateY,
                        position,
                    })}
                    className="translate-y-centered-offset top-1/2 right-0 w-[40vw] flex flex-col justify-center items-center text-center text-lg leading-tight"
                >
                    <p className="text-heading">Rejoignez-nous</p>
                    <motion.span
                        whileHover={{
                            scale: 1.05,
                            cursor: "pointer"
                        }}
                        whileTap={{ scale: 0.9 }}
                        transition={{ type: "spring", stiffness: 400, damping: 17 }}
                        className="mt-2 text-primary flex items-center space-x-2">
                        <img className="mt-1 inline-block h-6 w-6" src="https://i.imgur.com/RANJ45d.png" alt="Logo Twitch"/>
                        <span onClick={() => navigate('twitch')}>Twitch.tv/Aspig</span>
                    </motion.span>
                </motion.div>
                <div className="pagination">
                    <motion.p
                        className="text-bg"
                        style={stylesWithCssVar({
                            opacity: pagination1Opacity,
                        })}>2</motion.p>
                    <motion.p
                        className="text-heading"
                        style={stylesWithCssVar({
                            opacity: pagination2Opacity,
                        })}>3</motion.p>
                    <motion.p
                        className="text-bg"
                        style={stylesWithCssVar({
                            opacity: pagination3Opacity,
                        })}>4</motion.p>
                    <motion.p
                        className="text-heading"
                        style={stylesWithCssVar({
                            opacity: pagination4Opacity,
                        })}>5</motion.p>
                </div>








                <div className="background background-1">
                </div>
                <div className="background background-2">
                    <svg className="background-tri absolute top-0 transform rotate-180" data-v-443efe72="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 408">
                        <polygon className="background-tri" points="0,409 0,409 1920,330 1920,409" style={{fill: "var(--color-heading)"}}></polygon>
                    </svg>
                </div>
                <div className="background background-3">
                    <svg className="background-tri absolute top-0 transform rotate-180" data-v-443efe72="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 408">
                        <polygon className="background-tri" points="0,409 0,409 1920,330 1920,409" style={{fill: "var(--color-background)"}}></polygon>
                    </svg>
                </div>
                <div className="background background-4">
                    <svg className="background-tri absolute top-0 transform rotate-180" data-v-443efe72="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 408">
                        <polygon className="background-tri" points="0,409 0,409 1920,330 1920,409" style={{fill: "var(--color-heading)"}}></polygon>
                    </svg>
                </div>
                {/*<div className="background background5">*/}
                {/*    <svg className="background-tri absolute top-0 transform rotate-180" data-v-443efe72="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 408">*/}
                {/*        <polygon className="background-tri" points="0,409 0,409 1920,330 1920,409" style={{fill: "var(--color-background)"}}></polygon>*/}
                {/*    </svg>*/}
                {/*    <p>6</p>*/}
                {/*</div>*/}
            </div>
        </section>
    );
};

export default Networks;